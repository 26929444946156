export const teamStepsMarkdown = `{% tablessContainer %}
1. Open the {% inlineAppIcon iconName="userGroup" %}**My Teams**{% /inlineAppIcon %} section of the **Contacts** tab in the {% inlineRouterLink %}sidebar{% /inlineRouterLink %}.
2. Click **Create new team**.
3. Give the team a name and, optionally, add some initial members.
4. Click **Create team**.
{% /tablessContainer %}

{% callout type="tip" marginStyles="mt-4" %}
**Tip:** Team members can be existing Tower Hunt users or people who aren't yet on Tower Hunt. Try searching for a person's name or, failing that, providing an email address.
{% /callout %}`;

export const createATeam = {
  order: 1,
  name: "Create a team",
  subcategory: "Manage teams",
  markdown: `# Create a team

  In Tower Hunt, teams make it easy for groups of users to share datapoints and earnings. Teams can also pay for the datapoint usage of their members. These features make teams a great way to deliver more value to the people you work with inside or outside of your company. Read on for steps to create a new team.

  ${teamStepsMarkdown}`,
};
